import React from "react";
import Button from "../custom-widgets/button";

const BranchReview = (props) => {
  //console.log("BranchReview props: ", props);

  let myButton = { ...props.button };
  myButton.url = myButton.url || props.branch_location.PageURL;

  return (
    <>
      <div className={props.titleClass}>{props.ReviewTitle}</div>
      <div className={props.textClass}>
        <em>&ldquo;{props.ReviewText}&rdquo;</em>
      </div>
      <div className="row d-none d-lg-flex">
        <div className={props.buttonColClass}>
          <Button {...myButton} />
        </div>
        <div className={props.authorColClass}>
          <p className={props.authorClass}>
            <strong>{props.AuthorsName}</strong>
          </p>
        </div>
      </div>
      <div className="row d-flex d-lg-none">
        <div className={props.authorColClass}>
          <p className={props.authorClass}>
            <strong>{props.AuthorsName}</strong>
          </p>
        </div>
        <div className={props.buttonColClass}>
          <Button {...myButton} />
        </div>
      </div>
    </>
  );
};
export default BranchReview;

BranchReview.defaultProps = {
  id: 1,
  ReviewTitle: "", // from Strapi/graphQL query
  ReviewText: "", // from Strapi/graphQL query
  AuthorsName: "", // from Strapi/graphQL query
  PageURL: "", // from Strapi/graphQL query
  titleClass: "h4 my-3 text-black",
  textClass: "text-secondary font-italic mb-1 mb-sm-2 mb-md-3",
  authorColClass: "col-lg-5",
  authorClass: "mt-2 mb-3 text-black",
  buttonColClass: "col-lg-7",
  button: {
    id: "branch-review-1-button",
    class: "btn btn-link px-3",
    text: "See Branch Details",
    textXs: "Branch Details",
    showIcon: false
  }
};
